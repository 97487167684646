import moment from 'moment'
import { downloadFileNew, downloadFile } from '@/services/document'

export default {
  init(view) {
    view.params[this.name].buttons = view.page.buttons.map(button => {
      return {
        ...button,
        onClick: () => {
          this.handles(view).onClick(button)
        }
      }
    })
  },

  handles(view) {
    const functionalities = {
      dialog: button => {
        view.dialog({
          type: button.type,
          hasIcon: true,
          title: button.name,
          message: view.$t(button.props.dialogMessage),
          onCancel: () => {},
          onConfirm: () => {
            view.isLoading.list = true
            if (typeof button.props.magicButton !== 'undefined') {
              const mime = require('mime-types')
              const data = button.props.magicButton.data
              const hash = button.props.magicButton.hash ?? ''
              const map = button.props.magicButton.map
              const cannedReport = {}
              const moduleVars = view.$route.params ?? {}

              for (const attribute in data) {
                let needle = attribute

                if (
                  map[attribute] &&
                  typeof map[attribute].field_name !== 'undefined'
                ) {
                  needle = map[attribute].field_name
                }

                let fieldValue = moduleVars[needle]

                if (
                  map[attribute] &&
                  typeof map[attribute].force_value !== 'undefined'
                ) {
                  fieldValue = map[attribute].force_value
                }

                cannedReport[attribute] =
                  typeof fieldValue !== 'undefined' ? fieldValue : null
              }

              // eslint-disable-next-line
              const apiData = { 'canned_report': cannedReport }
              if (hash !== '') {
                apiData.hash = hash
              }

              downloadFileNew(button.props.submit, apiData).then(response => {
                if (response.status === 'error') {
                  view.toast({
                    type: 'is-danger',
                    message: view.$t(response.message)
                  })
                } else {
                  const fileName =
                    `${button.props.magicButton.name}` ?? 'Canned Report Export'
                  const extension =
                    mime.extension(response.type) === 'html'
                      ? 'xml'
                      : mime.extension(response.type)
                  const a = document.createElement('a')
                  const today = moment(Date.now()).format('DD.MM.YYYY HH.mm.ss')

                  // Canned Report 2.0
                  if (response.path) {
                    if (
                      'file' in response &&
                      'extension' in response &&
                      'name' in response
                    ) {
                      const a = document.createElement('a')
                      a.href = `data:application/${response.extension};base64,${response.file}`
                      a.download = response.name
                      a.click()
                    }
                  } else {
                    const url = window.URL.createObjectURL(response)
                    a.href = url
                    a.download = `${fileName} - ${today}.${extension}`

                    a.click()
                    window.URL.revokeObjectURL(url)
                  }
                }
                view.isLoading.list = false
              })
            } else {
              view.api({
                module: this,
                endPoint: button.props.submit,
                method: 'post',
                body: {
                  ...view.model[this.name]
                },
                callback: res => {
                  view.isLoading.list = false
                  if (
                    typeof res.file_name !== 'undefined' &&
                    typeof res.file_content !== 'undefined'
                  ) {
                    const data = Uint8Array.from(atob(res.file_content), c =>
                      c.charCodeAt(0)
                    )
                    const blob = new Blob([data], {
                      type: 'octet/stream'
                    })
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.style = 'visibility:hidden'
                    link.download = res.file_name
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)

                    view.params[button.props.moduleLink].checkedRows = []
                    return
                  }

                  if (res && res.actions) {
                    Object.keys(res.actions).forEach(key => {
                      if (res.actions[key].route_response.content.success) {
                        res.success =
                          res.actions[key].route_response.content.success
                        return
                      }
                      res.error = res.actions[key].route_response.content.error
                    })
                  }
                  view.toast({
                    type: res.success ? 'is-success' : 'is-danger',
                    duration: 5000,
                    message: view.$t(res.success || res.error)
                  })

                  if (view.model[this.name].ids) {
                    view.params[button.props.moduleLink].checkedRows = []
                  }

                  view.modules[button.props.moduleLink].uses.get(view)
                }
              })
            }
          }
        })
      },
      checkedRows: button => {
        view.model[this.name].ids = view.params[
          button.props.moduleLink
        ].checkedRows.map(c => c.id)
      }
    }

    return {
      onClick: button => {
        if (button.props.formInfoSlug) {
          return view.modal({
            component: 'DynamicForm',
            canCancel: ['x'],
            props: {
              res: null,
              model: view.model[this.name].modal,
              params: {
                title: button.props.infoTitle,
                forms: [button.props.formInfoSlug]
              },
              hasCard: 'false',
              handles: {
                api: view.api,
                onCreate: _this =>
                  view.api({
                    endPoint: 'authenticated-forms',
                    method: 'post',
                    body: {
                      form: button.props.formInfoSlug
                    },
                    callback: res => {
                      res = res.actions
                        ? res.actions[Object.keys(res.actions)[0]]
                            .route_response.content
                        : res

                      _this.formBuilder({
                        formDependency: button.props.formInfoSlug,
                        ...res,
                        fields: res.form
                      })
                    }
                  })
              },
              elements: this.handles(view).checkButtons(button)
            }
          })
        }

        if (button.portal_route) {
          return this.handles(view).useRouterByPortalRoute(button)
        }

        this.handles(view).callButtonFunctionalities(button)
      },

      checkButtons(button) {
        const elements = [
          {
            component: 'b-button',
            label: view.$t('button-close'),
            onClick: _this => _this.$emit('close'),
            style: {
              backgroundColor: '#2babe2',
              color: 'white',
              float: 'right'
            }
          }
        ]

        if (button.props.workflowEndPoint) {
          elements.push({
            component: 'b-button',
            label: view.$t('button-submit'),
            class: 'is-primary',
            onClick: _this =>
              view.api({
                endPoint: button.props.submit,
                method: 'post',
                body: {
                  workflowBody: JSON.stringify({
                    ..._this.form.model
                  })
                },
                callback: _ => _this.$emit('close')
              })
          })
        }

        return elements
      },

      callButtonFunctionalities(button) {
        const propsKeys = Object.keys(button.props)

        propsKeys.forEach(prop => {
          if (!Object.keys(functionalities).includes(prop)) return
          functionalities[prop](button)
        })
      },

      useRouterByPortalRoute: button => {
        if (button.portal_route === '$back') {
          return view.$router.back()
        }

        view.$router.push(button.portal_route)
      }
    }
  }
}
